/**
 * Application
 */
var Application = {};

/**
 * Construct
 */
Application.init = function() {
    Application.iCheck();
    Application.videoHeaderLoad();
    Application.videoAutoPlay();
    Application.newsletterSubmit();

    if($(window).width() > 990) {
        Application.windowViewer();
    }

    $('#header_soundcloud_iframe').mouseenter(function (e) {
        $(this).closest('.dropdown').addClass('dropdown-hovered');
    });

    $('#header_soundcloud_iframe').mouseleave(function (e) {
        $(this).closest('.dropdown').removeClass('dropdown-hovered');
    });
};

/**
 * Icheck input
 */
Application.iCheck = function() {

    $('.checkbox label input').iCheck({
        checkboxClass:  'icheckbox_minimal-white',
        radioClass:     'iradio_minimal-white',
        increaseArea:   '20%' // optional
    });

    $('.checkbox label input').on('ifChecked', function(event){
    $('.newsletter-subscription-button').attr("disabled", false);
    });

    $('.checkbox label input').on('ifUnchecked', function(event){
        $('.newsletter-subscription-button').attr("disabled", true);
    });
    console.log('Lefut az ICheck');
};

Application.newsletterSubmit = function()
{
    $('.newsletter-subscription-button').attr("disabled", true);
};

/**
 * Video header loader
 */
Application.videoHeaderLoad = function() {
    $(document).ready(function() {
        $('.video-header-image').hide();
        $('.video-header-video').removeClass('hide');
    });
};

/**
 * Soundcloud iframe loader
 * @param id
 * @param link
 */
Application.addIframePlayer = function(id, link) {
    $.ajax({
        url:            '/soundcloud/' + link,
        dataType:       'html',
        contentType:    "application/json; charset=utf-8",
        data:           {},
        type:           'get',
        success: function (result) {
            $('#soundcloud-iframe-' + id).html(result);
        },
    });
};
/**
 * windows resizer
 */
Application.windowViewer = function() {
    var w = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
    function widthChanger(elem1, elem2) {
        $(elem1).css('width', elem2);
    }
    widthChanger('.header-img', w);
    $(window).resize(function() {
        widthChanger('.header-img', w);
    });
};


/**
 * Autoplay embed video
 */
Application.videoAutoPlay = function() {
    $("video[autoplay]").each(function(){ this.play(); });
};


$(document).ready(function(){
    Application.init();
});