/**
 * SoundCloud class
 * @type {{}}
 */
var SoundCloud = {}

/**
 * Client Id
 * @type {string}
 */
SoundCloud.ClientId = '651f463c8fd070a72d4189b4b421e320';

/**
 * Player object
 * @type {null}
 */
SoundCloud.Player = null;

/**
 * Construct
 */
SoundCloud.init = function () {
    SC.initialize({
        client_id: '651f463c8fd070a72d4189b4b421e320'
    });

    SoundCloud.actionPlay();
    SoundCloud.actionPause();
    SoundCloud.actionReplay();
    SoundCloud.actionPreview();
    SoundCloud.parsePlayers();
};

/**
 * Each all track
 */
SoundCloud.parsePlayers = function () {
    $('.sc-player').each(function() {
        var id          = $(this).data('id');
        var soundcloud  = $(this).data('soundcloud');

        SoundCloud.getTrackDatas(id, soundcloud);
    });
};

/**
 * Get track datas
 * @param id
 * @param trackId
 */
SoundCloud.getTrackDatas = function (id, trackId) {
    $.ajax({
        url: 'https://api.soundcloud.com/tracks/' + trackId + '.json?client_id=' + SoundCloud.ClientId,
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        data: {},
        type: 'get',
        success: function (result) {
            $('.track-title-' + id).html(SoundCloud.splitString(result.title));
        },
    });
};

/**
 * split the title into 5 words and returns it, and give it ... in the end
 */
SoundCloud.splitString = function (e) {
    var arr = e.split(' ');
    var result = arr.length < 5 ? arr.join(' ') : arr.slice(0, 4).join(' ').concat('...');
    return result;
}

/**
 * Preview button action
 */
SoundCloud.actionPreview = function () {
    $('.sc-preview').click(function () {
        var trackId = $(this).attr('data-track-id');
        var itemId = $(this).attr('data-id');

        SoundCloud.stopAllPlayable();

        SC.stream('/tracks/' + trackId).then(function (player) {
            SoundCloud.Player = player;

            SoundCloud.Player.play();

            $('.player-item-' + itemId + ' .sc-play').removeClass('show').addClass('hide');
            $('.player-item-' + itemId + ' .sc-replay').removeClass('show').addClass('hide');
            $('.player-item-' + itemId + ' .sc-pause').removeClass('hide').addClass('show');

            $('.player-item-' + itemId).addClass('playable');
        });
    });
};

/**
 * Play button action
 */
SoundCloud.actionPlay = function () {
    $('.sc-play').click(function () {
        var trackId = $(this).attr('data-track-id');
        var itemId = $(this).attr('data-id');

        SoundCloud.stopAllPlayable();

        SC.stream('/tracks/' + trackId).then(function (player) {
            SoundCloud.Player = player;

            SoundCloud.Player.play();

            $('.player-item-' + itemId + ' .sc-play').removeClass('show').addClass('hide');
            $('.player-item-' + itemId + ' .sc-pause').removeClass('hide').addClass('show');

            $('.player-item-' + itemId).addClass('playable');
        });
    });
};

/**
 * Pause button action
 */
SoundCloud.actionPause = function () {
    $('.sc-pause').click(function () {
        var itemId = $(this).attr('data-id');

        SoundCloud.Player.pause();

        $('.player-item-' + itemId + ' .sc-pause').removeClass('show').addClass('hide');
        $('.player-item-' + itemId + ' .sc-replay').removeClass('hide').addClass('show');
    });
};

/**
 * Replay button action
 */
SoundCloud.actionReplay = function () {
    $('.sc-replay').click(function () {
        var itemId = $(this).attr('data-id');

        SoundCloud.Player.play();

        $('.player-item-' + itemId + ' .sc-replay').removeClass('show').addClass('hide');
        $('.player-item-' + itemId + ' .sc-pause').removeClass('hide').addClass('show');
    });
};

/**
 * Stop all playabled
 */
SoundCloud.stopAllPlayable = function() {
    $('.playable').each(function() {
        $(this).find('.sc-pause').removeClass('show').addClass('hide');
        $(this).find('.sc-play').removeClass('hide').addClass('show');
        $(this).removeClass('playable');
    });
};

/**
 *
 */
$(document).ready(function () {
    SoundCloud.init();
});