/**
 * Article
 * @type {{}}
 */
var Article = {};

/**
 * Construct
 */
Article.init = function() {
    Article.openSocialModalDialog();
    Article.ajaxPaginatorClick();
    Article.getArticlesList(false);
    Article.catchFilterForm();
    Article.catchAnchor();
    Article.GridDeleteAjax();
    Article.GridAddAjax();
    Article.inputCounter();
};

/**
 * Közösségi megosztás
 */
Article.openSocialModalDialog = function() {
    $('.social-modal-button').click(function() {
        url     = $(this).attr('data-url');
        title   = $(this).attr('data-title');

        window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=400,height=600");
    });
};

/**
 * Ajax lapozás gomb action
 */
Article.ajaxPaginatorClick = function() {
    $('.btn-more-article').click(function() {
        Article.ajaxPagerAction(this);
    });
};

/**
 * Ajax lapozás
 * @param me
 */
Article.ajaxPagerAction = function(me) {
    $('.article-paginator-loader').removeClass('hide');

    lastPage    = $('.paginator .pagination li:last').prev().text();
    url         = $(me).attr('data-uri');
    page        = $(me).attr('data-per-page');
    nextPage    = parseInt(page) + 1;

    $.ajax({
        url:            '/' + url + '?page=' + nextPage,
        dataType:       'html',
        contentType:    "application/json; charset=utf-8",
        data:           {},
        type:           'get',
        success:        function (result) {
            content = $(result).find('.current-article-page');

            $('.article-items').append(content);
            $('.btn-more-article').attr('data-per-page', nextPage);

            Article.listImagesResize();
            $('.article-paginator-loader').addClass('hide');

            if (lastPage == page) {
                $('.btn-more-article').addClass('hide');
            }
        }
    });
};

/**
 * Rapyd listázás ajaxos betöltése
 */
Article.getArticlesList  = function (obj) {
    if (!obj) {
        $.each($('.getRapydList'), function(key, value) {
            var ajaxURL     = $(value).data('url');

            $.get(ajaxURL, function(result) {
                $(value).html('');
                $(value).html(result.data);
            });
        });
    }
    else {
        var ajaxURL = obj.data('url');

        $.get(ajaxURL, function(result) {
            obj.html('');
            obj.html(result.data);
        });
    }
};

/**
 * Rapyd form elküldésének módosítása
 */
Article.catchFilterForm = function () {
    $('.getRapydList').on('submit', 'form', function (e) {
        var contentDiv  = $(this).closest('.getRapydList');
        var loaderDiv   = $(this).closest('.getRapydList').prev('.ajaxLoader');

        e.preventDefault();
        Article.showAjaxLoader(loaderDiv);
        $(this).ajaxSubmit({
            url:        $(this).attr('action'),
            type:       'get',
            dataType:   'json',
            success:    function(result) {
                contentDiv.html('');
                contentDiv.html(result.data);
                Article.hideAjaxLoader(loaderDiv);
            },
            error:      function() {
                App.showModal('Hiba történt a lekérdezés során!');
                Article.hideAjaxLoader(loaderDiv);
            }
        });

        return false;
    });
};

/**
 * Anchor linkek ajaxos kezelése
 */
Article.catchAnchor = function() {
    $('.getRapydList').on('click', 'a', function(e) {
        e.preventDefault();

        var anchorLink  = $(this).attr('href');
        if (!anchorLink) return false;

        var contentDiv  = $(this).closest('.getRapydList');
        var loaderDiv   = $(this).closest('.getRapydList').prev('.ajaxLoader');

        Article.showAjaxLoader(loaderDiv);
        $.get(anchorLink, function(result) {
        }).done(function(result) {
            contentDiv.html('');
            contentDiv.html(result.data);

            Article.hideAjaxLoader(loaderDiv);
        }).fail(function() {
            App.showModal('Hiba történt a lekérdezés során!');
            Article.hideAjaxLoader(loaderDiv);
        });

        return false;
    });
};

/**
 * Rapyd listázás törlésének kezelése
 * @constructor
 */
Article.GridDeleteAjax  = function() {
    $('.getRapydListConnected').on('click', '.ajaxGridDelete', function() {
        Article.doAction(this, $('#relatedArticlesList'));
    });
    $('.getRapydListSeries').on('click', '.ajaxGridDelete', function() {
        Article.doAction(this, $('#articleSeriesList'));
    });
};

/**
 * Rapyd listázás hozzáadása gomb kezelése
 */
Article.GridAddAjax = function() {
    $('.getRapydList').on('click', '.ajaxGridAdd', function() {
        Article.doAction(this, $('#relatedArticlesList'));
    });
    $('.getRapydList').on('click', '.ajaxGridAddSeries', function() {
        Article.doAction(this, $('#articleSeriesList'));
    });
};

/**
 * Rapyd ajaxos műveletek végrehajtása
 * @param obj
 */
Article.doAction    = function(obj, refreshObject) {
    if ($(obj).data('url')) {
        var loaderDiv   = $(obj).closest('.getRapydList').prev('.ajaxLoader');
        var listObject  = $(obj).closest('.getRapydList');
        Article.showAjaxLoader(loaderDiv);

        $.get($(obj).data('url'), function(result) {
        }).done(function(result) {
            //Article.getArticlesList(listObject);
            Article.hideAjaxLoader(loaderDiv);
            App.showModal(result.message);

            if (refreshObject) {
                Article.getArticlesList(refreshObject);
            }
        }).fail(function() {
            Article.hideAjaxLoader(loaderDiv);
            App.showModal('Hiba történt a lekérdezés során!');
        });
    }
};

/**
 * A listázáshoz tartozó loader megjelenítése
 * @param obj
 */
Article.showAjaxLoader = function(obj) {
    obj.fadeIn();
};

/**
 * A listázáshoz tartozó loader eltüntetése
 * @param obj
 */
Article.hideAjaxLoader = function(obj) {
    obj.fadeOut();
};

/**
 * Crop hozzárendelés
 */
Article.addCrop = function() {
    var element = '.jcrop';

    $(element).Jcrop({
        boxWidth:       '100%',
        boxHeight:      '100%',
        aspectRatio:    $(element).attr('data-width') / $(element).attr('data-height'),
        bgOpacity:      .1,
        setSelect:      [0, 0, $(element).attr('data-width'), $(element).attr('data-height')],
        allowResize:    true,
        onSelect:       function(c) {
            $('.image-crop-form-x').val(c.x);
            $('.image-crop-form-y').val(c.y);
            $('.image-crop-form-w').val(c.w);
            $('.image-crop-form-h').val(c.h);
        },
        onChange:       function(c) {
            $('.image-crop-form-x').val(c.x);
            $('.image-crop-form-y').val(c.y);
            $('.image-crop-form-w').val(c.w);
            $('.image-crop-form-h').val(c.h);
        },
    }, function() {
        Article.jcrop_api = this;
    });
}

/**
 * Crop művelet
 */
Article.cropAction = function() {
    formData = $('.image-crop-form').serialize();

    $.ajax({
        type:     'POST',
        url:      '/admin/articles/imageCrop',
        headers:  { 'X-CSRF-TOKEN' : $('input[name="_token"]').val() },
        data:     formData,
        dataType: 'html',
        success:  function (response) {
            window.location.reload();
        },
        error:     function(jqXHR, textStatus, errorThrown) {
            console.log('error');

            console.log(textStatus, errorThrown);
        }
    });
};

/**
 * Input field counter
 */
Article.inputCounter = function() {
    $('.input-counter').keyup(function() {
        var value           = $(this).val();
        var maxLength       = $(this).attr('maxlength');
        var counter         = maxLength - value.length;
        var counterElement  = $(this).data('counter');

        $(counterElement).find('.counter').html(counter);
    })
}

$(document).ready(function() {
    Article.init();
});