/**
 *
 * @type {{}}
 */
var Mobile = {};

/**
 * init function
 */
Mobile.init = function() {
    $('.sidemenu-btn').on('click',function(){
        $('#sidebarMob, .sidebarOverlay').toggle();
    });
}

/**
 * here the program calls the Mobile.init, and it manages the resize cases
 */
$(document).ready(function () {
    Mobile.init();
});