/**
 *
 * @type {{}}
 */
var IconChange          = {};

/**
 * this function manages the iconManager
 */
IconChange.init = function() {
    if($(window).width() > 990) {
        IconChange.iconManager();
    }
};

/**
 * this function manages the mouseover, and mouseout operation
 */
IconChange.iconManager = function() {
    $('.s-icon-content').mouseover(function() {
        $(this).find('.s-icon.mouseover').show();
        $(this).find('.s-icon.mouseout').hide();
    });

    $('.s-icon-content').mouseout(function() {
        $(this).find('.s-icon.mouseout').show();
        $(this).find('.s-icon.mouseover').hide();
    });
};

$(document).ready(function () {
    IconChange.init();

    /**
     * telefon input mask
     */
    $('.phone_with_ddd').mask('(00) 000-0000');
});